body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FCC52F;
  background-color: #292929; // or #21313c
}

.table {
  color: #FCC52F;

  td, th {
    border-top-width: 0px !important;
  }

  thead th, & > :not(caption) > * > * {
    border-bottom-color: darken( #FCC52F, 20);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body .btn-primary {
  color: #000;
}

.header-logo {
  height: 5rem;
}

.highlightContract {
  box-shadow: 0px 0px 50px -10px inset;
  // clip-path: inset(0px 0px 0px -15px);
  border-radius: 5px;
  
  // background: linear-gradient(to right, #FCC52F, transparent 15%);

  // text-shadow: 0 0 15px;
}

.collapsable {
  position: relative;
  .collapse-content {
  overflow-y: clip;
  }

  .collapse-indicator {
    position: absolute;
    top: 0;
    left: -3rem;
    font-size: 200%;
  }

  &.collapsed .collapse-content {
    height: 8rem;
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  }
}